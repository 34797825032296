import { setCookie } from 'cookies-next'
import { createStore } from 'zustand/vanilla'

const ACCOUNT_ID_KEY = 'accountId'
const DIRECT_ACCESS_ACCOUNT_ID_KEY = 'directAccessAccountId'
const options = {
  maxAge: 365 * 24 * 60 * 60, // One year
  path: '/',
  sameSite: true,
}

export type ContextState = {
  accountId: string | undefined
  directAccessAccountId: string | undefined
  isOpen: boolean
}

export type ContextActions = {
  setAccountId: (accountId: string) => void
  setDirectAccessAccountId: (directAccessAccountId: string) => void
  setAccountIdAndDirectAccessAccountId: (
    accountId: string,
    directAccessAccountId: string,
  ) => void
  setOpen: (isOpen: boolean) => void
}

export type ContextStore = ContextState & ContextActions

export const initContextStore = ({
  accountId,
  directAccessAccountId,
  isOpen,
}: ContextState): ContextState => {
  return { accountId, directAccessAccountId, isOpen }
}

export const defaultInitState: ContextState = {
  accountId: undefined,
  directAccessAccountId: undefined,
  isOpen: false,
}

export const createContextStore = (
  initState: ContextState = defaultInitState,
) => {
  return createStore<ContextStore>()((set) => ({
    ...initState,
    setAccountId: (accountId) => {
      set(() => ({ accountId }))
      setCookie(ACCOUNT_ID_KEY, accountId, options)
    },
    setDirectAccessAccountId: (directAccessAccountId) => {
      set(() => ({ directAccessAccountId }))
      setCookie(DIRECT_ACCESS_ACCOUNT_ID_KEY, directAccessAccountId, options)
    },
    setAccountIdAndDirectAccessAccountId: (
      accountId,
      directAccessAccountId,
    ) => {
      set(() => ({ accountId, directAccessAccountId }))
      setCookie(ACCOUNT_ID_KEY, accountId, options)
      setCookie(DIRECT_ACCESS_ACCOUNT_ID_KEY, directAccessAccountId, options)
    },
    setOpen: (isOpen) => set(() => ({ isOpen })),
  }))
}
