import { useContextStore } from '@/providers/context-store-provider'
import { useSession } from 'next-auth/react'
import { useMemo } from 'react'

export function useEnabled() {
  const { accountId } = useContextStore((state) => state)
  const sessionQuery = useSession()
  const accessToken = sessionQuery.data?.accessToken

  return useMemo(() => {
    return !!accessToken && !!accountId
  }, [accessToken, accountId])
}
